<template>
  <li>
    <p class="title" v-html="nbsp(item.title)" />
    <ul class="subitems">
      <WidgetsPracticalInfoWidgetPricingSubitem
        v-for="(subitem, index) in item.subitems"
        :key="index"
        :subitem="subitem"
      />
    </ul>
    <div
      v-if="item.additional_info"
      class="additional-info"
      v-html="nbsp(item.additional_info)"
    />
    <BaseButton
      v-if="item.ticket_link"
      class="cta"
      :base-link="item.ticket_link"
    />
    <BaseLink v-if="item.more_link" class="more-link" :link="item.more_link">
      {{ t('learn-more') }}
    </BaseLink>
  </li>
</template>

<script lang="ts" setup>
import type { PublicPricingVariantItem } from '~/service/__generated-api'

defineProps<{
  item: PublicPricingVariantItem
}>()

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.title {
  @include font-size(
    (
      xs: 16px
    ),
    1.2
  );
  margin-bottom: rem(31px);
  font-weight: 500;

  @include media-up(md) {
    margin-bottom: rem(31px);
  }

  @include media-up(lg) {
    margin-bottom: rem(17px);
    font-size: rem(14px);
    line-height: rem(18.76px);
  }
}

.subitems {
  display: flex;
  flex-direction: column;
  gap: rem(10px);
}

.additional-info {
  margin-top: rem(25px);
  font-size: rem(13px);
  font-style: italic;
  line-height: rem(14px);
  text-align: center;
}

.cta {
  max-width: rem(240px);
  margin-inline: auto;
  margin-top: rem(52px);

  @include media-up(lg) {
    max-width: rem(226px);
  }

  .contrast-mode & :deep(.button) {
    color: $contrast-yellow !important;
    background-color: $contrast-black !important;
  }
}

.more-link {
  display: block;
  margin-top: rem(25px);
  font-size: rem(15px);
  line-height: 1;
  text-align: center;
  text-decoration: underline;

  @include media-up(xl) {
    font-size: rem(17px);
  }
}
</style>
